<template>
  <!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
  <DataTable :value="listDataService" :lazy="true" class="p-datatable-responsive" selectionMode="single" data-key="id"
    @page="onPage($event)" :paginator="pagination" :totalRecords="totalRecords" :rows="paginationRows"
    :loading="loading" :first="firstRecordIndex" :scrollable="(scrollHeight !== '' ? true : false)"
    :scrollHeight="scrollHeight" :autoLayout="true" filterMatchMode="startsWith"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate="$constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM + ' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE">
    <Column v-for="c in model.getDisplayFields()" :key="c.key" :field="getField(c)" :header="getLabel(c)"
      :ref="getField(c)" :filterMatchMode="getFilterMatch(c)" :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')" :bodyStyle="getBodyStyle(c)" :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')">
      <template #body="slotProps">
        <img v-if="getType(c) == 'Image'" :src="getData(c, slotProps.data)" style="width: 40px; heigth: 40px" />
        <div v-else-if="c.type == 'Text'" v-html="getData(c, slotProps.data)" :style="(c.style ? c.style : '')"></div>
        <div v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'" v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"></div>
        <div v-else-if="getType(c) == 'CustomUrl' && view_club == true" v-html="callbackCustomUrl(c, slotProps.data)"
          @click="redirectListClub(slotProps.data, $event)" :style="(c.style ? c.style : '')"></div>
        <div v-else-if="getType(c) == 'CustomUrl'" v-html="callbackCustomUrl(c, slotProps.data)"
          @click="redirectListClub(slotProps.data, $event)" :style="(c.style ? c.style : '')"></div>

        <Checkbox v-else-if="getType(c) == 'Checkbox'" v-model="slotProps.data[slotProps.column.field]" :binary="true"
          @change="onInputChange($event, slotProps.data, c)" />
        <div v-else>
          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge" />
            <div v-if="c.custom == 'SelectionText'" v-html="callbackDataSelect(c, slotProps.data)"></div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)"
            :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>


      </template>
      <!-- <template v-if="c.filter" #filter>
        <InputText v-if="c.filter.type == 'input'" type="text" v-model="filters[getField(c)]" class="p-column-filter"
          :placeholder="$CoreService.getFilterPlaceHolder(c)" @keyup="onFilter($event, c.filter.type, c.key)" />
        <Dropdown :key="countDropdown" v-if="c.filter.type == 'select'" v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)" :optionLabel="c.filter.optionLabel" :optionValue="c.filter.optionKey"
          :placeholder="$CoreService.getFilterPlaceHolder(c)" class="p-column-filter" :showClear="true" appendTo="body"
          @change="onFilter($event, c.filter.type, c.key)">
          <template #option="slotProps">
            <div class="p-clearfix">
              <span :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)">{{
                slotProps.option[c.filter.optionLabel] }}</span>
            </div>
          </template>
        </Dropdown>
        <Calendar v-if="c.filter.type == 'DateRange'" :showOnFocus="false" :monthNavigator="true" :yearNavigator="true"
          :yearRange="yearRange" dateFormat="MM dd, yy":placeholder="$CoreService.getFilterPlaceHolder(c)"
          v-model="filters[getField(c)]" class="p-column-filter calendar-light-btn" :showIcon="true"
          selectionMode="range" appendTo="body" :showClear="true" :showButtonBar="true"
          @clear-click="onFilter($event, c.filter.type, c.key)" @date-select="onFilter($event, c.filter.type, c.key)"
          :manualInput="false" style="width: 200px;" />

        <Calendar v-if="c.filter.type == 'Date'" :showOnFocus="false" :monthNavigator="true" :yearNavigator="true"
          :yearRange="yearDOB" dateFormat="MM dd, yy":placeholder="$CoreService.getFilterPlaceHolder(c)"
          v-model="filters[getField(c)]" class="p-column-filter calendar-light-btn" :showIcon="true"
          selectionMode="single" appendTo="body" :showClear="true" :maxDate="new Date()" :showButtonBar="true"
          @clear-click="onFilter($event, c.filter.type, c.key)" @date-select="onFilter($event, c.filter.type, c.key)"
          :manualInput="false" style="width: 200px;" />
      </template> -->
    </Column>
    <Column v-if="actions" header="Action" headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center; justify-content: center">
      <template #body="slotProps">
        <span class="p-column-title">Actions</span>
        <Button v-for="(a, k) in actions.rows(slotProps.data)" :key="k" type="button" :icon="getIcon(a.icon)"
          :class="getClass(a.name)" :disabled="getDisabled(a.disabled)" style="margin: 2px"
          v-on:click="actionsClicked(k, a, slotProps)"></Button>
      </template>
    </Column>

    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
    // view_club:Boolean ,
  },
  data() {
    return {
      filters: {},
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService: null,
      filtersCustom: [],
      lazyParams: {},
      page_transaction: null,
      firstRecordIndex: 0,
      yearRange: (new Date().getFullYear() - 100) + ":" + (new Date().getFullYear() + 100),
      yearDOB: (new Date().getFullYear() - 100) + ":" + new Date().getFullYear(),
      time_zone_local: moment.tz.guess(true),
      view_club: false,
    };

  },
  async created() {
    var year = new Date().getFullYear()
    var yearRange = (year - 10) + ':' + (year + 90);
    var yearDOB = (year - 100) + ':' + year;
    this.yearRange = yearRange + '';
    this.yearDOB = yearDOB + '';
    this.test = this.tableData;
    var arr_role_clubs = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_TERM_OF_USE_POLICY_CHIL);
    // console.log('role_clubrole_club',arr_role_clubs)
    if (arr_role_clubs.indexOf(this.$constants.ROLE.VIEW) < 0) {
      this.view_club = false;
    } else {
      this.view_club = true;
    }
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };
    /*
    * stateStorage="session"
    stateKey="dt-state-demo-session"
    * phai them thuoc tinh nay vao table
    * */
    if (!this.$commonFuction.isEmpty(this.$route.query)) {
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        this.firstRecordIndex = this.lazyParams.first;
        // console.log("this.lazyParams:22222222",this.lazyParams);
        /*if (this.lazyParams){
          this.filters = (this.lazyParams.filters) ? this.lazyParams.filters : {};
        }*/
      }
    }
    this.loading = false
    this.countDropdown++;
    this.onLazyEvent();
  },
  methods: {
    redirectListClub(data, e) {
      let clickedElId = e.target.id
      console.log(data.id)
      if (clickedElId === 'list-users-clubs') {
        var id = data.id;
        var path = `/admin/list-users_clubs/${id}`;
        this.$router.push({ path: path })
      }
    },
    callbackCustomUrl(c, data) {
      var text = ''
      switch (c.key) {
        case 'club_html':
          if (data.view_users_clubs_expiration_aggregate && data.view_users_clubs_expiration_aggregate.aggregate) {
            var count_club = data.view_users_clubs_expiration_aggregate.aggregate.count
            if (this.view_club == true) {
              text = '<a class="txt-link" id="list-users-clubs" :data-id="' + data.id + '">' + count_club + '</a>';
            } else {
              text = count_club;
            }
          } else {
            text = 0;
          }
          break;
        default:
          break;
      }
      // this.date_now
      return text;
    },
    select_date(event, col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if (col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange) f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "text-align:center;";
      if (col.width) style += "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style += "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style += "width: 6em;";
      return style;
    },
    getBodyStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "text-align:center; width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      // console.log("this.options:",this.options)
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment.tz(row[col.key], this.time_zone_local).format("LL");
        // value = moment.tz(row[col.key], this.time_zone_local).format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment.tz(row[col.key], this.time_zone_local).format("ll HH:mm");
        // value = moment.tz(row[col.key], this.time_zone_local).format("HH:mm - DD/MM/yyyy");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], this.time_zone_local).format("HH:mm - DD/MM/yyyy");
      } else if (col.type == "Hour" && row[col.key]) {
        value = moment.tz(row[col.key], this.time_zone_local).format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          // console.log("col.models:",col.models);
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter(a => a.value === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      }
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      if (col.filter.placeHolder) {
        return col.filter.placeHolder;
      } else if (col.filter.type == "select") {
        return `Chọn`;
      } else if (col.filter.type == "input") {
        return `Input`;
      } else if (col.filter.type == "DateRange") {
        return `Choose`;
      } else if (col.filter.type == "Date") {
        return `Choose`;
      } else {
        return ``;
      }
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams: this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
    callbackDataSelect(c, data) {
      console.log("sasasawqwqfdf")
      var text = "";
      switch (c.key) {
        // key la cai name
        case 'active':
          if (data.active == true) {
            text = `<i style="color: green" class="pi pi-check icon"></i>`;
          } else {
            text = `<i style="color: red" class="pi pi-times icon"></i>`;
          }
          break;
        default:
      }
      return text;
    },
    async dataList(lazyParams) {
      // console.log('lazyParams');
      // console.log('lazyParams:',lazyParams);
      var offset = lazyParams.first;
      var limit = lazyParams.rows;
      console.log("this.model.tableName:", this.model.tableName);
      var gqLQueryList = DataServices.getList(this.model.tableName);
      var where = {
        deleted: { _eq: false },
        //_and: []
      }
      var filters = lazyParams.filters;
      if (filters) {
        for (const [key, value] of Object.entries(filters)) {
          //  console.log("dsadsd",value.type_input);
          if (value.type_input == 'input') {
            if (!this.$commonFuction.isEmpty(value.value)) {
              if (key == 'property_name') {
                where.property = { name: { _iregex: this.$commonFuction.search(value.value) } };
              } else {
                where[key] = { _iregex: this.$commonFuction.search(value.value) };
              }
            }
          }
          else if (value.type_input == 'select') {
            // console.log("filters",filters)
            if (key == "updated_name") {
              if (value.value !== undefined && value.value !== null) {
                where['updated_by'] = { _eq: value.value }
              }
            } else if (key == "done") {
              // if(value.value !== undefined && value.value !== null){

              // }
            } else {
              if (value.value !== undefined && value.value !== null) {
                where[key] = { _eq: value.value }
              }
            }
          } else if (value.type_input == 'DateRange') {
            if (value.value !== undefined && value.value !== null) {
              if (key == 'start_end') {
                where["_and"].push({ _or: [{ end_booking_date: { '_gte': value.value.to }, start_booking_date: { '_lte': value.value.from } }, { start_booking_date: { '_gte': value.value.from }, end_booking_date: { '_lte': value.value.to } }] });
              } else if (key == 'from_to') {
                where["_and"].push({ _or: [{ end_visit_date: { '_gte': value.value.to }, start_visit_date: { '_lte': value.value.from } }, { start_visit_date: { '_gte': value.value.from }, end_visit_date: { '_lte': value.value.to } }] });
              } else {
                where[key] = { '_gte': value.value.from, '_lte': value.value.to }
              }
            }
          } else if (value.type_input == 'Date') {
            if (value.value !== undefined && value.value !== null) {
              // console.log("dsadasdas");
              if (key == "full_birthday") {
                where[key] = { _eq: moment(value.value).tz("Asia/Ho_Chi_Minh").format("yyyy-MM-DD") }
              } else {
                where[key] = { _eq: value.value }
              }
            }

          }
        }

      }
      console.log("gqLQueryListgqLQueryList:", gqLQueryList)
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key: where,
          offset: offset,
          limit: limit,
          orderBy: { created_at: 'desc' }
        }
      });
      var listData = resData.data[this.model.tableName];

      var gqLQueryCount = DataServices.countData(this.model.tableName);
      var countRes = await this.$apollo.mutate({
        mutation: gqLQueryCount,
        variables: {
          where_key: where
        }
      });
      var total = countRes.data[this.model.tableName + '_aggregate']['aggregate']['count'];

      var dataRes = {
        listData: listData,
        total: total,
      };
      //  console.log('dataRes:',dataRes);
      return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      // console.log('this.lazyParams:',this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(async data => {
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        for (var i = 0; i < data.listData.length; i++) {
          var itemData = data.listData[i]
          stt++;
          data.listData[i].stt = stt;
          console.log('gia tri cua data la : ' + data.listData[i].stt);
          data.listData[i].updated_by_key = itemData?.obj_updated_by?.fullname ?? '';
          /* var booking_round = data.listData[i].booking_round.filter(f => f?.status && f.status != 'ST001');
           console.log("booking_round" , booking_round);
           data.listData[i].property_name = data.listData[i]?.property?.name || "-";
           data.listData[i].part = (data.listData[i]?.property?.part ? data.listData[i].property.part.length : 0) + '/5';
           data.listData[i].from_to = data.listData[i]?.start_visit_date ? `${moment.tz(data.listData[i]?.start_visit_date, this.time_zone_local).format("LL")} ${data.listData[i]?.end_visit_date ? ' - ' + moment.tz(data.listData[i]?.end_visit_date, this.time_zone_local).format("LL") : null}` : "-";
           data.listData[i].start_end = data.listData[i]?.start_booking_date ? `${moment.tz(data.listData[i]?.start_booking_date, this.time_zone_local).format("LL")} ${data.listData[i]?.end_booking_date ? ' - ' + moment.tz(data.listData[i]?.end_booking_date, this.time_zone_local).format("LL") : null}` : "-";
           data.listData[i].done = `${booking_round.length}/25`;
           data.listData[i].updated_name = data.listData[i]?.user_update?.fullname;*/

        }
        // console.log(data.listData)
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
      });
    },
    onPage(event) {
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event, type_input, key) {
      this.tableData = this.test;
      if (type_input == "DateRange") {
        if (this.filters[key] !== null && this.filters[key][1] == null) {
          return false;
        }
      }
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input, key, this.filters);
      this.lazyParams.first = 0;
      this.onLazyEvent()
    },
  }
};
</script>
<style lang="scss">
.p-paginator-bottom {
  padding: 0 !important;
}

.p-inputtext {
  width: 100%;
}

.p-button-label {
  color: white;
}
</style>